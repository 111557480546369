import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div className="home-upper">
      <div className="eos-logo">
        <Image />
      </div>
    </div>

    <div className="home-lower">
       <h1>404 Error</h1>
       <p><strong>Page Not Found</strong></p>
      <p>Sorry the page you are looking for has either been deleted of moved.</p>
      <p><Link to="/">Go Home</Link></p>
    </div>
  </Layout>
)

export default NotFoundPage
